<template>
  <div class="product-grid product-grid_full">
    <div class="product-grid__title-wrapper mb_m">
      <div class="product-grid__title font font_title-xl font_bold font_center">
        {{ title }}
      </div>
    </div>

    <ul class="product-grid__list profile-promo__products-item">
      <li v-for="product in products" :key="product.id" class="product-grid__item">
        <ProductCard :item="product" />
      </li>
    </ul>
  </div>
</template>

<script>
import ProductCard from '~/components/catalog/productCard';
export default {
  name: 'ProductGrid',
  components: {ProductCard},
  props: {
    title: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      required: true,
    },
  },
}
</script>

